import { FirebaseError } from 'firebase/app';
import { signOut, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth } from '../../../firebase-config';
import errorHistory from '../error-api/ErrorAPI';
import { navigateTo } from '../navigation-api/NavigationAPI';

/**
 * Provides a Google sign-in popup.
 */
export const signIn = () => {
  const provider = new GoogleAuthProvider();
  provider.setCustomParameters({
    prompt: 'select_account',
  });
  signInWithPopup(auth, provider)
    .then(() => {
      navigateTo('/');
    })
    .catch((error: unknown) => {
      if (error instanceof FirebaseError) {
        const {
          code,
          message,
        } = error;
        errorHistory.setAndSend(code, message);
      } else if (error instanceof Error) {
        throw error;
      } else {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    });
};

/**
 * Signs a user out of their login session
 */
export const signOutHandler = () => {
  signOut(auth)
    .then(() => {
      navigateTo('/login');
      localStorage.clear();
    })
    .catch((error: unknown) => {
      if (error instanceof FirebaseError) {
        errorHistory.updateErrorCode(error.code);
        errorHistory.updateErrorMessage(error.message);
        navigateTo('/error');
      } else if (error instanceof Error) {
        throw error;
      } else {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    });
};
