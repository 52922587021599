import React from 'react';
import errorAPI from '../../../api/error-api/ErrorAPI';
import { tr } from '../../../api';

/**
 * Contains the text portion of the ErrorPage.
 * Extracts string from StringAPI category Error
 * @constructor
 */
export const ErrorDescription = function () {
  return (
    <div className="flex flex-col justify-center items-center">
      <div className="text-4xl">
        <span className="font-semibold">{tr('error_title_one')}</span>
        {tr('error_title_two')}
        <hr className="westland-red border-2" />
      </div>
      <div className="mt-2 text-xl">{tr('error_subtitle')}</div>
      <div className="mt-2 mb-2 flex justify-center items-center text-xs">
        {/* { */}
        <span className="text-5xl mr-2">&#123;</span>
        <div>
          <div className="text-sm">
            <span className="error">{tr('error_message')}</span>
            {errorAPI.getErrorMessage()}
          </div>
          <div className="text-sm">
            <span className="error">{tr('error_code')}</span>
            {errorAPI.getErrorCode()}
          </div>
        </div>
        {/* } */}
        <span className="text-5xl ml-2 ">&#125;</span>
      </div>
    </div>
  );
};
