import React, {
  SetStateAction, useContext, useEffect, useState,
} from 'react';
import { onAuthStateChanged, User } from 'firebase/auth';
import { auth } from '../../../firebase-config';
import history from '../history/History';

type Children = {
  children: React.ReactNode;
};
/**
 * A shorthand method to get the current user.
 * WARNING: This method isn't initialize tolerant. This means it should be used in
 * cases when you know the user auth data is already loaded,
 * or use an observer (isLoggedInObserver) instead if it may be
 * a race case.
 *
 * Its primary usage is location where you know the user has already been defined.
 */
export const getUser = () => auth.currentUser;

/**
 * A wrapper for the onAuthStateChanged observer for firebase.
 * Returns the user auth data inside the provided state hook.
 * @param setUser a use state hook for holding the user results.
 */
export const isLoggedInObserver = (
  setUser: React.Dispatch<SetStateAction<User | null>>,
) => (
  onAuthStateChanged(auth, (user) => {
    setUser(user);
    if (!user) {
      history.push('/login');
    }
  }));

/**
 * Defines the UserContext which will handle the 'User' object.
 */
const UserContext = React.createContext<User | null>(null);
const AdminContext = React.createContext<boolean>(false);

/**
 * A facade for the user to use the user context without the setup
 */
export const useUser = () => useContext(UserContext);
export const useAdmin = () => useContext(AdminContext);

/**
 * A context management for the user object. Instead of having to call a listener
 * inside each functional component, we can make use of React.Context to
 * define the UserProvider which make the user available through the useUser
 * useContext above in all components beneath it.
 * @param children
 * @constructor
 */
export const UserProvider = function ({ children }: Children) {
  const [user, setUser] = useState<User | null>(null);
  const [isUserAdmin, setIsUserAdmin] = useState<boolean>(false);

  useEffect(() => {
    if (user) {
      user.getIdTokenResult()
        .then((idTokenResult) => {
          setIsUserAdmin(idTokenResult.claims.admin as unknown as boolean);
        }).catch(() => {});
    }
  }, [user]);

  useEffect(() => {
    const res = isLoggedInObserver(setUser);
    return () => res();
  }, []);

  return (
    <UserContext.Provider value={user}>
      <AdminContext.Provider value={isUserAdmin}>
        {children}
      </AdminContext.Provider>
    </UserContext.Provider>
  );
};
