import { useTranslation } from 'react-i18next';
import { TOptionsBase } from 'i18next';

type TranslationKeys =
    'error_code' | 'error_message' | 'error_subtitle' | 'error_title_one' | 'error_title_two' |
    'login_button' | 'login_title' |
    'not_found_text' | 'not_found_button';

export const tr = (key: TranslationKeys, optKeys?: TOptionsBase & object & { defaultValue: string; }) => {
  const { t } = useTranslation();
  const translatedString = optKeys ? t(key, optKeys) : t(key);
  const invalidKeyString = `${key} is not defined`;

  return translatedString || invalidKeyString;
};
