import React, { useMemo } from 'react';
import {
  FormControl, InputLabel, MenuItem, Select, Skeleton,
} from '@mui/material';
import { FrequencyOptions } from '../landing-page-types';
import { SyncFrequencyOptionsParams } from '../landing-page-params';
import { frequencyHourFormatted } from '../../../api/landing-page-api/LandingPageAPI';

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
// generates list of 1-28 since those are the only days present in all months
const daysOfMonth = Array.from(Array(28), (_, index) => index + 1);

export const SyncFrequencyOptions = function ({
  syncJob,
  updateSyncJob,
  loadingSheetData,
}: SyncFrequencyOptionsParams) {
  if (loadingSheetData) return <Skeleton variant="rectangular" className="w-full h-12 mt-10" />;
  const { selectedFrequency, selectedDayOfMonth, selectedDayOfWeek } = syncJob;

  const displayHourText = selectedFrequency !== FrequencyOptions.HOURLY
      && selectedFrequency !== FrequencyOptions.MANUAL;
  const isWeekly = selectedFrequency === FrequencyOptions.WEEKLY;
  const isMonthly = selectedFrequency === FrequencyOptions.MONTHLY;

  const frequencyText = `Syncs will occur around ${frequencyHourFormatted}.`;

  const frequencyOptions = useMemo(() => Object.values(FrequencyOptions), []);

  return (
    <>
      <div className="flex flex-row items-end mt-10">
        {/* Primary frequency selector */}
        <FormControl className="w-44 mr-4">
          <InputLabel>Sync Frequency</InputLabel>
          <Select
            value={selectedFrequency}
            label="Sync Frequency"
            variant="standard"
            onChange={({ target }) => updateSyncJob(
              { ...syncJob, selectedFrequency: target.value as FrequencyOptions },
            )}
          >
            {frequencyOptions.map(
              (option) => (<MenuItem key={option} value={option}>{option}</MenuItem>),
            ) }
          </Select>
        </FormControl>
        {/* Show day of week selector for weekly frequency */}
        {isWeekly && (
          <>
            on
            <FormControl className="w-44 mx-4">
              <InputLabel>Day of Week</InputLabel>
              <Select
                value={selectedDayOfWeek}
                renderValue={() => daysOfWeek[selectedDayOfWeek]}
                label="Day of Week"
                variant="standard"
                onChange={({ target }) => {
                  // use unary operator to convert target.value to number since it will always be string
                  updateSyncJob({ ...syncJob, selectedDayOfWeek: +target.value });
                }}
              >
                {daysOfWeek.map((option, index) => (
                  <MenuItem key={option} value={index}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}
        {/* Show day of month selector for monthly frequency */}
        {isMonthly && (
          <>
            on
            <FormControl className="w-44 mx-4">
              <InputLabel>Day of Month</InputLabel>
              <Select
                value={selectedDayOfMonth}
                label="Day of Month"
                variant="standard"
                onChange={({ target }) => {
                  // use unary operator to convert target.value to number since it will always be string
                  updateSyncJob({ ...syncJob, selectedDayOfMonth: +target.value });
                }}
              >
                {daysOfMonth.map((option) => (
                  <MenuItem key={option} value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}
      </div>
      {/* Display text detailing the default hour of sync if frequency is non-hourly */}
      {displayHourText && (
      <div className="text-xs mt-2">{frequencyText}</div>
      )}
    </>
  );
};
