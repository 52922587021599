import { HomeIcon } from '@heroicons/react/24/outline';
import history from '../history/History';
import { NavigationItem, PageType } from './NavigationTypes';

/**
 * A helper method to provide navigation to endpoints.
 * @param location: string
 * @param absolute: boolean Use absolute path
 * @param state: optional state
 */
export const navigateTo = (location: string, absolute?:boolean, state?: Record<string, unknown>) => {
  history.push(absolute ? `/${location}` : location, state);
};

/**
 * Serves as the external definition for the navigation paths.
 * While this could be placed inside a component directly,
 * it felt clearer to store it within the navigation API
 */
export const getNavigationItems = (): NavigationItem[] => [
  {
    id: PageType.Home,
    href: '/',
    name: 'Home',
    adminRestricted: false,
    icon: HomeIcon,
  },
];
