import {
  DataGridPro, GridColDef, GridRenderCellParams, GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import {
  Box, Chip, IconButton, Skeleton,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Circle, Delete, Edit, OpenInNew,
} from '@mui/icons-material';
import cronstrue from 'cronstrue';
import dayjs from 'dayjs';
import {
  JobRunHistory, JobRunStatus, SheetSyncJob, WriteDisposition,
} from '../landing-page-types';
import { JobsDataGridParams } from '../landing-page-params';
import { ManualSyncButton } from './ManualSyncButton';
import { getLastRun, getShortenedTableId, watchJobRunHistory } from '../../../api/landing-page-api/LandingPageAPI';

export const generateColumns = (
  updateDeleteModalJobId: (id: string) => void,
  updateEditModalJobId: (id: string) => void,
  interactive: boolean,
  jobRunHistory: JobRunHistory[],
): GridColDef[] => {
  const lastJobRunNotFoundText = "Hasn't run yet";
  const coreColumns = [
    {
      field: 'jobName',
      headerName: 'Job Name',
      flex: 1,
    },
    {
      field: 'sheetName',
      headerName: 'Sheet Name',
      flex: 1,
    },
    {
      field: 'tableId',
      headerName: 'Table ID',
      flex: 1,
      // shortens table ID for readability
      valueGetter: ({ row }: GridValueGetterParams<SheetSyncJob>) => getShortenedTableId(row.tableId),
    },
    {
      field: 'refreshCron',
      headerName: 'Sync Schedule',
      flex: 1,
      // displays cron as a more readable, descriptive string
      valueGetter: ({ row }: GridValueGetterParams<SheetSyncJob>) => (
        row.refreshCron ? cronstrue.toString(row.refreshCron) : 'Manual'
      ),
    },
    {
      field: 'writeMethod',
      headerName: 'Upload Method',
      flex: 1,
      valueGetter: (
        { row }: GridValueGetterParams<SheetSyncJob>,
        // gets more readable version of write disposition
      ) => (row.writeMethod === WriteDisposition.APPEND ? 'Append' : 'Overwrite'),
    },
    {
      field: 'uuidColumn',
      headerName: 'ID Column',
      flex: 1,
    },
    {
      field: 'owner',
      headerName: 'Owner',
      flex: 1,
      // if a job is in the table and not shared, it is owned by the current user
      valueGetter: ({ row }: GridValueGetterParams<SheetSyncJob>) => (row.shared ? 'Shared' : 'You'),
    },
    {
      field: 'lastRunStatus',
      headerName: 'Last Run Status',
      flex: 1,
      // if a job is in the table and not shared, it is owned by the current user
      renderCell: ({ row }: GridRenderCellParams<SheetSyncJob>) => {
        const lastRun = getLastRun(jobRunHistory, row.jobId);
        if (!lastRun) return (<div>{lastJobRunNotFoundText}</div>);
        const color = lastRun.runStatus === JobRunStatus.SUCCESS ? 'bg-green-300' : 'bg-red-300';
        const style = `${color} mx-auto text-gray-800`;
        return (<Chip className={style} label={lastRun.runStatus} />);
      },
    },
    {
      field: 'lastRunTimestamp',
      headerName: 'Last Run Time',
      flex: 1,
      // if a job is in the table and not shared, it is owned by the current user
      valueGetter: ({ row }: GridValueGetterParams<SheetSyncJob>) => {
        const lastRun = getLastRun(jobRunHistory, row.jobId);
        if (!lastRun) return lastJobRunNotFoundText;
        return dayjs.unix(lastRun.runTimestamp.seconds).format('M/DD/YY h:mma');
      },
    },
  ];
  const interactiveColumns: GridColDef[] = [
    {
      field: 'editJob',
      headerName: 'Edit',
      headerAlign: 'center',
      flex: 0.5,
      // opens edit job modal (manageJobModal} in edit mode)
      renderCell: ({ row }: GridRenderCellParams<SheetSyncJob, string>) => (
        <IconButton
          className="mx-auto"
          onClick={() => updateEditModalJobId(row.jobId)}
        >
          <Edit />
        </IconButton>
      ),
    },
    {
      field: 'deleteJob',
      headerName: 'Delete',
      headerAlign: 'center',
      flex: 0.5,
      // opens delete job modal
      renderCell: ({ row }: GridRenderCellParams<SheetSyncJob, string>) => (
        <IconButton
          className="mx-auto"
          onClick={() => updateDeleteModalJobId(row.jobId)}
        >
          <Delete />
        </IconButton>
      ),
    },
    {
      field: 'manualSync',
      headerName: 'Sync',
      headerAlign: 'center',
      flex: 0.5,
      // manually runs a sync for the given job
      renderCell: ({ row }: GridRenderCellParams<SheetSyncJob, string>) => ManualSyncButton(row.jobId),
    },
    {
      field: 'workbookLink',
      headerName: 'Link',
      headerAlign: 'center',
      flex: 0.5,
      // displays a link to the related workbook of a given job
      renderCell: ({ row }: GridRenderCellParams<SheetSyncJob, string>) => (
        <IconButton
          className="mx-auto"
          onClick={() => { window.open(row.workbookUrl, '_blank'); }}
        >
          <OpenInNew className="w-4" />
        </IconButton>
      ),
    },
  ];
  return interactive ? [...coreColumns, ...interactiveColumns] : coreColumns;
};

export const JobsDataGrid = function ({
  updateDeleteModalJobId,
  updateEditModalJobId,
  jobRows,
  interactive,
  height,
  jobsLoading,
}: JobsDataGridParams) {
  const [jobRunHistory, setJobRunHistory] = useState<JobRunHistory[]>([]);
  const columns = useMemo(
    () => generateColumns(updateDeleteModalJobId, updateEditModalJobId, interactive, jobRunHistory),
    [updateDeleteModalJobId, updateEditModalJobId, interactive, jobRunHistory],
  );

  // Gets real-time jobRun data updates from Firestore
  useEffect(() => {
    const unsubscribe = watchJobRunHistory(setJobRunHistory);
    return () => unsubscribe();
  }, []);

  const containerStyle = { height, width: '90%' };

  if (jobsLoading) return <Skeleton sx={containerStyle} variant="rectangular" />;

  return (
    <Box sx={containerStyle}>
      <DataGridPro
        rows={jobRows}
        columns={columns}
        getRowId={({ jobId }: SheetSyncJob) => jobId}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
      />
    </Box>
  );
};
