import React, { useEffect, useState } from 'react';
import {
  Route,
  Routes,
  useNavigate,
} from 'react-router-dom';
import { CenteredCircularProgress } from './components/helper';
import history from './components/api/history/History';
import errorAPI from './components/api/error-api/ErrorAPI';
import {
  ErrorPage, LandingPage, LoginPage, NotFoundPage,
} from './components/pages';
import { useUser } from './components/api';

export const App = function () {
  const navigate = useNavigate();
  const [historyEnabled, setHistoryEnabled] = useState(false);

  useEffect(() => {
    history.listen((location) => {
      navigate(
        location.location.pathname,
        { state: location.location.state },
      );
    });
    setHistoryEnabled(true);
  }, []);

  const user = useUser();

  return (
    <>
      {historyEnabled && (
        <Routes>
          <Route path="/">
            <Route path="login" element={<LoginPage />} />
            {errorAPI.doesErrorExist() && (<Route path="error" element={<ErrorPage />} />)}
            { user
                    && (
                    <>
                      <Route index element={<LandingPage />} />
                      {/*
                    Allows the 404 Page to be rendered if the user manually searches up the error
                    page.
                  */}
                      <Route path="*" element={<NotFoundPage />} />
                    </>
                    )}
          </Route>
        </Routes>
      )}
      {!historyEnabled && (<CenteredCircularProgress />)}
    </>
  );
};
