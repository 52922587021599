import axios from 'axios';
import { CreateSyncJobReq, EditJobReq, ManualSyncReq } from '../../pages/landing-page/landing-page-types';

const backendUrl = 'https://us-central1-wreg-sheet-sync.cloudfunctions.net/sheetSyncApp';

const sheetsEndpoint = `${backendUrl}/sheets`;
const jobsEndpoint = `${backendUrl}/jobs`;

/**
 * Retrieves sheet names of a given Google Sheets workbook.
 * @param workbookId the ID of the workbook
 * @param userEmail email of the current user
 */
export const getWorkbookSheets = (
  workbookId: string,
  userEmail: string,
) => axios.get(`${sheetsEndpoint}/${workbookId}/${userEmail}`);

/**
 * Retrieves first 5 rows of a given sheet in a Google Sheets workbook.
 * @param workbookId ID of the workbook
 * @param userEmail email of the current user
 * @param sheetName name of the sheet that the rows are being retrieved from
 */
export const getPreviewRows = (
  workbookId: string,
  userEmail: string,
  sheetName: string,
) => axios.get(`${sheetsEndpoint}/rows/${workbookId}/${userEmail}/${sheetName}`);

/**
 * Creates a sync job within the jobs table in Postgres.
 * @param payload
 */
export const createSyncJob = (
  payload: CreateSyncJobReq,
) => axios.post(`${jobsEndpoint}/create-job`, payload);

/**
 * Retrieves all jobs from the jobs table in Postgres.
 */
export const getJobs = () => axios.get(jobsEndpoint);

/**
 * Deletes a job from the jobs table in Postgres.
 * @param jobId ID of the job to delete
 * @param dropTable whether or not to delete the Bigquery table associated with the job being deleted
 */
export const deleteJob = async (jobId: string, dropTable: boolean) => {
  const body = { dropTable };
  return axios.post(`${jobsEndpoint}/delete-job/${jobId}`, body);
};

/**
 * Edit a job in the jobs table in Postgres.
 * @param jobId ID of the job being edited
 * @param payload the job fields being changed
 */
export const editSyncJob = async (
  jobId: string,
  payload: EditJobReq,
) => axios.post(`${jobsEndpoint}/edit-job/${jobId}`, payload);

/**
 * Triggers manual sync of a job.
 * @param syncReq the request data to trigger a manual sync
 */
export const manualSync = async (syncReq: ManualSyncReq) => axios.post(`${jobsEndpoint}/sync`, syncReq);
