import { Checkbox, FormControlLabel, Skeleton } from '@mui/material';
import React from 'react';
import { SharedNamespaceCheckboxParams } from '../landing-page-params';

export const SharedNamespaceCheckbox = function ({
  syncJob, existingSyncJob, updateSyncJob, loadingSheetData,
}: SharedNamespaceCheckboxParams) {
  const sharedCheckboxLabel = 'Make this job private?';
  if (loadingSheetData) return <Skeleton variant="rectangular" className="w-full h-8 mt-6" />;
  return (
    <>
      <FormControlLabel
        control={(
          <Checkbox
            // Shared is the inverse of private, so we want to use the inverse value and set the inverse value
            checked={!syncJob.shared}
            disabled={!!existingSyncJob}
            onChange={({ target }) => updateSyncJob({ ...syncJob, shared: !target.checked })}
          />
            )}
        className="mt-6"
        label={sharedCheckboxLabel}
      />
      {/*  Only show warning label when creating a job */}
      {!existingSyncJob && (<div className="text-xs text-red-500"> Warning: this cannot be undone!</div>)}
    </>

  );
};
