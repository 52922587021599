import { Skeleton, TextField } from '@mui/material';
import React from 'react';
import { JobNameInputParams } from '../landing-page-params';

export const JobNameInput = function ({
  syncJob, updateSyncJob, jobNameIsNotUnique, loadingSheetData,
}: JobNameInputParams) {
  if (loadingSheetData) return <Skeleton variant="rectangular" className="w-full h-16 mt-8" />;
  return (
    <TextField
      label="Job name"
      variant="outlined"
      className="mt-8"
      value={syncJob.jobName}
      onChange={({ target }) => updateSyncJob({ ...syncJob, jobName: target.value })}
      error={jobNameIsNotUnique}
      helperText={jobNameIsNotUnique ? 'Already exists a job with that name' : ''}
    />
  );
};
