import i18next from 'i18next';
import HttpBackend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const apiKey = 'Owbxm47nUGd0sSsE45qY-A';
const loadPath = `https://api.i18nexus.com/project_resources/translations/{{lng}}/{{ns}}.json?api_key=${apiKey}`;

// For production environments it is recommended to use the i18nexus CDN and implement browser caching
// https://i18nexus.com/quick-integration/
void i18next
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',

    ns: ['default'],
    defaultNS: 'default',

    supportedLngs: ['en', 'es', 'de'],

    backend: {
      loadPath,
    },
  });
