import React, { useContext, useState } from 'react';
import { AlertColor } from '@mui/material/Alert/Alert';
import { SnackbarAlert } from '../../helper';

type Children = {
  children: React.ReactNode;
};

/**
 * The AlertHelper class provides a convenient wrapper around
 * the common calls one may wish to make to the SnackbarAlert.
 */
export class AlertHelper {
  private readonly setMessage: (newMessage: string) => void;

  private readonly setStatus: (newStatus: AlertColor) => void;

  private readonly setOpen: (isOpen: boolean) => void;

  private readonly setTimeout: (newTimeout?: number) => void;

  constructor(
    setMessage: (newMessage: string) => void,
    setOpen: (isOpen: boolean) => void,
    setStatus: (newStatus: AlertColor) => void,
    setTimeout: (newTimeout?: number) => void,
  ) {
    this.setMessage = setMessage;
    this.setStatus = setStatus;
    this.setOpen = setOpen;
    this.setTimeout = setTimeout;
  }

  updateMessage(newMessage: string) {
    this.setMessage(newMessage);
  }

  updateStatus(newStatus: AlertColor) {
    this.setStatus(newStatus);
  }

  updateOpen(isOpen: boolean) {
    this.setOpen(isOpen);
  }

  updateTimeout(timeout?: number) {
    this.setTimeout(timeout);
  }

  alert(newMessage: string, newStatus: AlertColor, newTimeout?: number) {
    this.setMessage(newMessage);
    this.setStatus(newStatus);
    this.setTimeout(newTimeout);
    this.setOpen(true);
  }
}

/**
 * Defines AlertContext which will handle the 'AlertHelper' object.
 */
const AlertContext = React.createContext<AlertHelper | null>(null);

/**
 * A facade for the user to use the alert context without the setup
 */
export const useAlert = () => useContext(AlertContext);

/**
 * A context management for the alert object.
 * Allows for the calling of the AlertHelper class which control a cross page
 * SnackbarAlert component
 * @param children
 * @constructor
 */
export const AlertProvider = function ({ children }: Children) {
  const [message, setMessage] = useState('Hi');
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState<AlertColor>('success');
  const [timeout, setTimeout] = useState<number | undefined>();

  const updateMessage = (newMessage: string) => setMessage(newMessage);
  const updateOpen = (isOpen: boolean) => setOpen(isOpen);
  const updateStatus = (newStatus: AlertColor) => setStatus(newStatus);
  const updateTimeout = (newTimeout?: number) => setTimeout(newTimeout);

  const [alertHelper, setAlertHelper] = useState(
    new AlertHelper(
      updateMessage,
      updateOpen,
      updateStatus,
      updateTimeout,
    ),
  );

  return (
    <AlertContext.Provider value={alertHelper}>
      <SnackbarAlert
        message={message}
        open={open}
        setOpen={setOpen}
        status={status}
        timeout={timeout}
      />
      {children}
    </AlertContext.Provider>

  );
};
