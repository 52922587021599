import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyC3KIZQ8adVxRzaX-y_XiHSlY2XktMjWA8',
  authDomain: 'wreg-sheet-sync.firebaseapp.com',
  projectId: 'wreg-sheet-sync',
  storageBucket: 'wreg-sheet-sync.appspot.com',
  messagingSenderId: '317062602240',
  appId: '1:317062602240:web:215141872bbd53f8dc42e5',
  measurementId: 'G-58S83G5TP7',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const functions = getFunctions();
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
