import React from 'react';
import { Button } from '@mui/material';
import notFoundRobot from '../../../assets/robots/404-Robot.svg';
import { navigateTo, tr } from '../../api';

/**
 * This component is responsible for the rendering of the not found page
 * Used whenever a user goes to an invalid route
 * @constructor
 */
export const NotFoundPage = function () {
  return (
    <div className="flex h-screen dark-charcoal justify-between">
      <div className="ml-24 flex flex-col justify-center">
        <span className="font-montserrat text-9xl text-red-700">404</span>
        <span className="text-2xl text-white">
          {tr('not_found_text')}
          <Button
            onClick={() => navigateTo('/')}
            className="font-montserrat text-red-700 text-xl"
            variant="text"
          >
            {tr('not_found_button')}
          </Button>
        </span>
      </div>

      <img className="ml-20 h-90vh" src={notFoundRobot} alt="A robot repairing itself, 404 page" />
    </div>
  );
};
