import {
  FormControl, FormHelperText, InputLabel, MenuItem, Select, Skeleton,
} from '@mui/material';
import React from 'react';
import { SheetSelectorParams } from '../landing-page-params';
import { ApiErrorCode } from '../landing-page-types';

export const SheetSelector = function ({
  syncJob,
  updateSyncJob,
  apiError,
  existingSyncJob,
  sheetOptions,
  loadingSheetOptions,
}: SheetSelectorParams) {
  if (loadingSheetOptions) return <Skeleton variant="rectangular" className="mt-8 h-16 w-full" />;

  // if apiError's error code is relevant to the sheetName/this component, then display apiError's message
  const sheetNameErrorMessage = apiError?.errorCode === ApiErrorCode.SHEET_ALREADY_SYNCED ? apiError.message : '';

  return (
    <FormControl className="mt-8">
      <InputLabel>Sheet</InputLabel>
      <Select
        value={syncJob.selectedSheet}
        label="Sheet"
        onChange={({ target }) => updateSyncJob({ ...syncJob, selectedSheet: target.value })}
        disabled={!!existingSyncJob}
        error={!!sheetNameErrorMessage}
      >
        {sheetOptions.map((sheet) => (
          <MenuItem key={sheet} value={sheet}>{sheet}</MenuItem>

        ))}
      </Select>
      {/*  Error text if sheet name has already been synced in the chosen dataset */}
      {sheetNameErrorMessage && (
        <FormHelperText className="text-red-500">{sheetNameErrorMessage}</FormHelperText>
      )}
    </FormControl>
  );
};
