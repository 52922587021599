import { Timestamp } from 'firebase/firestore';

/**
 * This represents a syncJob object (returned from Postgres).
 */
export interface SheetSyncJob {
  jobId: string,
  workbookId: string,
  jobName: string,
  comments: string,
  sheetName: string,
  tableId: string,
  refreshCron: string,
  writeMethod: string,
  userEmail: string,
  uuidColumn: string | null
  workbookUrl: string,
  tableName: string,
  shared: boolean,
  displaySyncFreqType: FrequencyOptions,
  displaySyncFreqDetail: number | null
}

/**
 * This represents a row of an unknown Google Sheet.
 */
export interface SheetRow {
  [header: string]: string
}

/**
 * These are the different sync frequency options.
 */
export enum FrequencyOptions {
  MANUAL = 'Manual',
  HOURLY = 'Hourly',
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
}

/**
 * This is the request body passed when making a call to the backend to edit a SyncJob.
 */
export interface EditJobReq {
  overwriteOnSync: boolean,
  identifierColumn: string
  frequencyCron: string
  displaySyncFreqType: string,
  displaySyncFreqDetail: number | null
  comments: string,
  jobName: string
}

/**
 * This is the request body passed when making a call to the backend to create a SyncJob.
 */
export interface CreateSyncJobReq extends EditJobReq {
  workbookId: string
  selectedSheet: string
  tableName: string
  userEmail: string
  shared: boolean
  workbookUrl: string,
}

export interface ManualSyncReq {
  jobId: string,
  userEmail: string
}

/**
 * This represents the error structure returned from the backend when there is an error that needs to be parsed by
 * the frontend.
 */
export interface ApiError {
  errorCode: string,
  message: string
}

/**
 * These are the two different write dispositions/methods when writing to a table in BigQuery.
 */
export enum WriteDisposition {
  APPEND = 'WRITE_APPEND',
  OVERWRITE = 'WRITE_TRUNCATE',
}

/**
 * These are the values of a SyncJob (when creating/editing a job on the frontend).  This differs slightly
 * from the data that is returned when getting SyncJobs from the backend as there is some parsing before storage.
 */
export interface JobValues {
  workbookUrl: string,
  selectedSheet: string,
  shared: boolean,
  tableName: string,
  overwriteOnSync: boolean,
  identifierColumn: string,
  selectedFrequency: FrequencyOptions,
  selectedDayOfWeek: number,
  selectedDayOfMonth: number,
  jobName: string,
  comments: string
}

/**
 * These are api error codes for known errors returned in the {@link ApiError} object.
 */
export enum ApiErrorCode {
  TABLE_ALREADY_EXISTS = 'table_already_exists',
  SHEET_ALREADY_SYNCED = 'sheet_already_synced_in_namespace',
  SHEET_PERMISSIONS_INVALID = 'permissions_invalid_for_sheet',
}

/**
 * Status of a SyncJobRun.
 */
export enum JobRunStatus {
  SUCCESS = 'Success',
  FAILURE = 'Failure',
}

/**
 * Represents one run of a SyncJob.
 */
export interface JobRun {
  message: string,
  runId: string,
  runStatus: JobRunStatus
  runTimestamp: Timestamp
}

/**
 * Represents history of all job runs for a given syncJob.
 */
export interface JobRunHistory {
  history: JobRun[]
  jobId: string
}
