import React, { useEffect, useMemo, useState } from 'react';
import { Button } from '@mui/material';
import { PlusIcon } from '@heroicons/react/24/outline';
import { AxiosResponse } from 'axios';
import { PageType } from '../../api/navigation-api/NavigationTypes';
import { SheetSyncContainer } from '../../helper';
import { ManageJobModal } from './manage-job-modal/ManageJobModal';
import { JobsDataGrid } from './jobs-datagrid/JobsDataGrid';
import { DeleteJobModal } from './DeleteJobModal';
import { SheetSyncJob } from './landing-page-types';
import { useAlert } from '../../api/alert-api/AlertAPI';
import { filterJobsForUser } from '../../api/landing-page-api/LandingPageAPI';
import { alertError } from '../../api/error-api/ErrorAPI';
import { useUser } from '../../api';
import { getJobs } from '../../api/endpoints-api/EndpointsAPI';

export const LandingPage = function () {
  const [createJobModalOpen, setCreateJobModalOpen] = useState<boolean>(false);
  // id of a job being deleted - dictates whether delete modal is open
  const [deleteModalJobId, setDeleteModalJobId] = useState<string>('');
  // id of a job being edited - dictates whether edit modal is open
  const [editJobModalId, setEditJobModalId] = useState<string>('');
  // jobs rows which populate the jobs Datagrid
  const [jobRows, setJobRows] = useState<SheetSyncJob[]>([]);
  const [jobsLoading, setJobsLoading] = useState<boolean>(true);

  const alert = useAlert();
  const user = useUser();

  // retrieves all jobs viewable to the current user
  useEffect(() => {
    if (!user) return;
    getJobs()
      .then(({ data }: AxiosResponse<SheetSyncJob[]>) => {
        // filter jobs for relevant jobs
        const rows = filterJobsForUser(data, user);
        setJobRows(rows);
      })
      .catch((err) => { throw alertError(alert, 'Failed to pull jobs', err); })
      .finally(() => setJobsLoading(false));
  }, [createJobModalOpen, deleteModalJobId, editJobModalId]);

  const updateDeleteModalJobId = (id: string) => setDeleteModalJobId(id);
  const updateEditModalJobId = (id: string) => setEditJobModalId(id);
  // job which is being edited (if there is one)
  const jobBeingEdited = useMemo(
    () => jobRows.find(({ jobId }) => jobId === editJobModalId),
    [jobRows, editJobModalId],
  );
  // job which is being deleted (if there is one)
  const jobBeingDeleted = useMemo(
    () => jobRows.find(({ jobId }) => jobId === deleteModalJobId),
    [jobRows, deleteModalJobId],
  );

  return (
    <SheetSyncContainer focusedPage={PageType.Home} className="bg-gray-50 h-[100vh] w-[100vw]">
      <div className="ml-44 font-inter flex flex-col">
        <div className="text-5xl font-semibold mt-10 mb-20">Sheet Sync</div>
        {/*  Jobs datagrid */}
        <JobsDataGrid
          updateDeleteModalJobId={updateDeleteModalJobId}
          updateEditModalJobId={updateEditModalJobId}
          jobRows={jobRows}
          interactive
          height={400}
          jobsLoading={jobsLoading}
        />
        {/* Create new job modal */}
        {createJobModalOpen && (
        <ManageJobModal
          modalOpen={createJobModalOpen}
          closeModal={() => setCreateJobModalOpen(false)}
          jobs={jobRows}
        />
        )}
        {/* Edit job modal */}
        {editJobModalId && jobBeingEdited && (
        <ManageJobModal
          modalOpen={!!editJobModalId}
          closeModal={() => setEditJobModalId('')}
          existingSyncJob={jobBeingEdited}
          jobs={jobRows}
        />
        )}
        {/* Delete job modal */}
        {deleteModalJobId && jobBeingDeleted && (
        <DeleteJobModal
          deleteModalJobId={deleteModalJobId}
          updateDeleteModalJobId={updateDeleteModalJobId}
          jobBeingDeleted={jobBeingDeleted}
        />
        )}
        {/*  Sync new sheet button */}
        <Button
          startIcon={<PlusIcon className="h-6" />}
          className="absolute right-20 bottom-20 bg-blue-500 text-white hover:text-white hover:bg-blue-400
          w-fit rounded-3xl p-4"
          onClick={() => setCreateJobModalOpen(true)}
        >
          Sync New Sheet
        </Button>
      </div>
    </SheetSyncContainer>
  );
};
