import React, { SyntheticEvent } from 'react';
import { Alert, Snackbar } from '@mui/material';
import { AlertColor } from '@mui/material/Alert/Alert';

export type SnackbarType = {
  message: string,
  open: boolean,
  setOpen: (status: boolean) => void,
  status: AlertColor,
  timeout?: number
};

/**
 * Displays a message to the top right of the screens
 * @param message :string -> The message to display
 * @param open :boolean -> The display state of the message
 * @param setOpen :func -> The function for setting the open state
 * @param status :SnackbarType -> The border color of the alert
 * @param timeout :number -> The time it takes for the alert to close
 * @constructor
 */
export const SnackbarAlert = function ({
  message,
  open,
  setOpen,
  status,
  timeout,
}: SnackbarType) {
  const vertical = 'top';
  const horizontal = 'right';
  const timeoutDuration = timeout || 6000;

  // If the user clicks on the 'X', close.
  const handleClose = (event: SyntheticEvent<Element, Event> | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical,
          horizontal,
        }}
        open={open}
        autoHideDuration={timeoutDuration}
        onClose={handleClose}
        key={vertical + horizontal}
      >
        <Alert onClose={handleClose} severity={status} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};
